export default class CompanyDetailsViewModel {
    public id: number = 0;
    public name: string = '';
    public workingWeekStart: number = 0;
    public holidayFromDay: number = 1;
    public holidayFromMonth: number = 0;
    public country: number = 0;
    public entitlement: number = 0;
    public afternoonStartHour: number = 12;
    public afternoonStartMin: number = 0;
}