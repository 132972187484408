import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { FormContainer, TextFieldElement, SelectElement } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import authService from '../api-authorization/AuthorizeService';
import { AlertContext } from '../Contexts/AlertContext';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import CompanyDetailsViewModel from './Viewmodels/CompanyDetailsViewModel';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/en-gb';
import Button from '@mui/material/Button';
import { monthDayValues, Months } from '../Utilities/CalendarEnums';
import LoadingButton from '@mui/lab/LoadingButton';
import SubscriptionStatusViewModel from '../Billing/Viewmodels/SubscriptionStatusViewModel';
import Alert from '@mui/material/Alert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InvoiceDialog from './InvoiceDialog';
import Paper from '@mui/material/Paper';


const dayOfWeekOptions = [
    { id: 0, label: 'Sunday' },
    { id: 1, label: 'Monday' },
    { id: 2, label: 'Tuesday' },
    { id: 3, label: 'Wednesday' },
    { id: 4, label: 'Thursday' },
    { id: 5, label: 'Friday' },
    { id: 6, label: 'Saturday' },
];

const ukCountries = [
    { id: 0, label: 'England' },
    { id: 1, label: 'Scotland' },
    { id: 2, label: 'Wales' },
    { id: 3, label: 'Northern Ireland' },
];

export default function CompanyDetails() {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [currentDetails, setCurrentDetails] = React.useState(new CompanyDetailsViewModel());
    const [stripeLoading, setStripeLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = React.useState(new SubscriptionStatusViewModel());
    const [saving, setSaving] = React.useState(false);

    const formContext = useForm<CompanyDetailsViewModel>({
        defaultValues: new CompanyDetailsViewModel()
    });
    const { reset } = formContext;

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        reset(currentDetails)
    }, [currentDetails]);

    const closeEdit = (refresh: boolean) => {
        if (refresh) getData();
    }

    const goToUpgrade = async () => {
        const token = await authService.getAccessToken();
        setStripeLoading(true);

        crabFetch('Billing/GoToUpgrade', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'text/plain; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain; charset=utf-8' }
        }, ResponseType.Text,
            (data: any) => {
                if (data.includes('http')) {
                    window.location.href = data;
                } else {
                    show('error', data);
                }

                setStripeLoading(false);
            }
        );
    }

    const goToBillingPortal = async () => {
        const token = await authService.getAccessToken();
        setStripeLoading(true);

        crabFetch('Billing/GoToBillingPortal', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'text/plain; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain; charset=utf-8' }
        }, ResponseType.Text,
            (data: string) => {
                if (data.includes('http')) {
                    window.location.href = data;
                } else {
                    show('error', data);
                }

                setStripeLoading(false);
            }
        );
    }

    const getData = async () => {
        const token = await authService.getAccessToken();
        crabFetch('Company/GetCompanyDetails', {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setCurrentDetails(data);

            }
        );

        crabFetch(`Billing/GetSubscriptionStatus`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setSubscriptionStatus(data);
            }
        );

    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        setSaving(true);
        crabFetch('Company/UpdateCompanyDetails', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully updated company details");
                    closeEdit(true);
                    setSaving(false);
                }
            },
            (error: any) => {
                show('error', error);
                setSaving(false);
            }
        );
    }

    const watchFromMonth = formContext.watch('holidayFromMonth');

    const monthList = monthDayValues.map((item) => {
        return { id: item.month, label: Months[item.month - 1].toString() };
    });

    const fromDayOptions = React.useMemo(() => {
        if (watchFromMonth) {
            return Array.from({ length: monthDayValues[watchFromMonth - 1].days }, (v, i) => { return { id: i + 1, label: i + 1 } });
        } else
            return Array.from({ length: monthDayValues[0].days }, (v, i) => { return { id: i + 1, label: i + 1 } });
    }, [watchFromMonth]);




    const hours = Array.from({ length: 8 }, (v, i) => { return { id: i + 9, label: i + 9 } });

    const mins = [
        { id: 0, label: "00" },
        { id: 15, label: "15" },
        { id: 30, label: "30" },
        { id: 45, label: "45" },
    ];


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{ pb: 2 }} variant="h3">Company Details</Typography>
                    <Paper sx={{ p: 3, position: 'relative' }}>
                        <FormContainer formContext={formContext} onSuccess={submit}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
                                        <Grid container item spacing={2}>
                                            <Grid item xs={4}>
                                                <InputLabel htmlFor="name" shrink>Name</InputLabel>
                                                <TextFieldElement autoComplete='off' name="name" required fullWidth validation={{
                                                    validate: value => value.trim() !== "" || "Field cannot be empty"
                                                }} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <InputLabel htmlFor="country" shrink>Country</InputLabel>
                                                {ukCountries.length > 0 && <SelectElement options={ukCountries} name="country"></SelectElement>}
                                            </Grid>
                                            <Grid item xs={4}>
                                                <InputLabel htmlFor="workingWeekStart" shrink>Working Week Start Day</InputLabel>
                                                <SelectElement name="workingWeekStart" required fullWidth options={dayOfWeekOptions} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <InputLabel htmlFor="holidayFrom" shrink>Holiday From</InputLabel>
                                                <SelectElement name="holidayFromMonth" required options={monthList} sx={{ pr: 2 }} />
                                                {fromDayOptions.length > 0 && <SelectElement name="holidayFromDay" required options={fromDayOptions} />}
                                            </Grid>
                                            <Grid item xs={4}>
                                                <InputLabel htmlFor="entitlement" shrink>Standard Full Time Entitlement</InputLabel>
                                                <TextFieldElement type="number" autoComplete='off' name="entitlement" required />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <InputLabel htmlFor="afternoonStartHour" shrink>Afternoon Start Time</InputLabel>
                                                <SelectElement options={hours} sx={{ pr: 2 }} name="afternoonStartHour"></SelectElement>
                                                <SelectElement options={mins} name="afternoonStartMin"></SelectElement>
                                            </Grid>
                                        </Grid>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item>
                                    <LoadingButton loading={saving} variant="contained" type="submit">Save Changes</LoadingButton>
                                </Grid>
                            </Grid>
                        </FormContainer>


                        <InvoiceDialog open={open} onClose={setOpen} />
                    </Paper>
                    <Typography sx={{ pb: 2, pt: 2 }} variant="h3">Billing Details</Typography>
                    <Paper>
                        <Grid container spacing={2} alignItems="center" justifyContent="space-between">

                            {subscriptionStatus.status === "trialing" && !subscriptionStatus.hasPaymentMethod &&
                                <Grid item>
                                    <Alert variant="outlined" severity="info" action={<LoadingButton size="small" endIcon={<OpenInNewIcon />} loading={stripeLoading} variant="contained" onClick={goToBillingPortal}>Upgrade Now</LoadingButton>}>
                                        You have {subscriptionStatus.trialDaysRemaining} days left of your free trial.
                                    </Alert>
                                </Grid>
                            }
                            {subscriptionStatus.status === "trialing" && subscriptionStatus.hasPaymentMethod &&
                                <Grid item>                                    <Alert variant="outlined" severity="info" action={<LoadingButton size="small" endIcon={<OpenInNewIcon />} loading={stripeLoading} variant="contained" onClick={goToBillingPortal}>Manage Subscription</LoadingButton>}>
                                    You are subscribed. You will be charged when your free trial ends in {subscriptionStatus.trialDaysRemaining} days.
                                </Alert>
                                </Grid>
                            }
                            {(subscriptionStatus.status !== "trialing" && subscriptionStatus.status !== "active") &&
                                <Grid item>
                                    <Alert variant="outlined" severity="info" action={<LoadingButton size="small" endIcon={<OpenInNewIcon />} loading={stripeLoading} variant="contained" onClick={goToBillingPortal}>Upgrade Now</LoadingButton>}>
                                        Your subscription has expired. Upgrade now to keep using teamplanner.
                                    </Alert>
                                </Grid>
                            }
                            {subscriptionStatus.status === "active" &&
                                <>
                                    <Grid item>
                                    <Alert variant="outlined" severity="info" action={<><LoadingButton sx={{ mr: 1 }} size="small" endIcon={<OpenInNewIcon />} loading={stripeLoading} variant="contained" onClick={goToBillingPortal}>Manage Subscription</LoadingButton> <LoadingButton  size="small" endIcon={<OpenInNewIcon />} loading={stripeLoading} variant="contained" onClick={goToUpgrade}>Manage Payment Methods</LoadingButton></>}>
                                            You're using <b>{subscriptionStatus.usedSeats} of {subscriptionStatus.seatsInSubscription}</b> paid seats. Add more seats or manage your subscription here:
                                        </Alert>
                                    </Grid>
                                </>
                            }
                            <Grid item>
                                <Button variant="contained" onClick={() => setOpen(true)}>Pay via Invoice</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );


}
