import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function CalculationExplainerDialog(props: IProps) {
    const { open, onClose } = props;

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogContent>
                <Grid container>

                    <Typography>
                        <p>
                            Your total entitlement is based on the number of days you work on an average week in your shift pattern.
                        </p>
                        <p>
                            <b>Example 1: You work 3 out of 5 days per week. Therefore, you are entitled to 60% of the maximum yearly entitlement. If your contract states the maximum entitlement for a full-time employee is 28 days, then you will be entitled to 17 days. (28 x 60% = 16.8, rounded up to the nearest whole or half day = 17 days)</b>
                        </p>
                        <p>
                            <b>Example 2: You work 9-days throughout a two-week shift pattern (and don't work any extra hours on your working days), therefore you work on average 4.5 days a week. This means you are entitled to 90% of the maximum which is 25.5 days (rounded up to the nearest whole or half day)</b>
                        </p>
                        <p>
                            If you change your contracted days (and therefore have a new shift pattern) within a given year, the UK law is unclear on how to calculate this. Teamplanner uses the <a href="https://www.bpe.co.uk/news-and-events/2015/12/calculating-holiday-entitlement-when-an-employee-s-hours-change/" target="_blank">European Court of Justice's (ECJ) guidance</a> on how to calculate entitlement in these situations.
                        </p>
                        <p>
                            The guidance recommends calculating the entitlement separately for each shift pattern and adding them together.
                        </p>
                        <p>
                            <b>Example 3: You work full-time for 6 months before switching to work 2 and a half days a week for the rest of the year. The guidance stipulates we treat these separately. Therefore, for the first 6 months, you are entitled to 14 days (based on a 28 day max) and, for the remaining 6 months, another 7. The total you are entitled to for the year is 21 days.</b>
                        </p>
                        <p>
                            This calculation uses the same rules that dictate what happens when you start or end your employment halfway through a month. In some cases, this can lead to you being incorrectly entitled to more than the maximum for a full-time member of staff. Please note we clamp this to be no greater than the max.
                        </p>
                        <p>
                            <b>Please see the government guidelines to learn more about how entitlement is calculated. Teamplanner follows this guidance closely, but please speak to your manager if you feel your calcuation is incorrect.</b>
                        </p>
                    </Typography>
                </Grid>
                <DialogActions>
                    <Button onClick={() => onClose(true)} variant="outlined">Close</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
