import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import Typography from '@mui/material/Typography';
import authService from '../api-authorization/AuthorizeService';
import Bugsnag from '@bugsnag/js';
import { useIdleTimer } from 'react-idle-timer'


export enum ResponseType {
    JSON,
    Text,
    Blob,
    Response
}

type ContextType = {
    crabFetch: (url: string, options: any, type: ResponseType, responseFunc?: (data: any) => void, catchFunc?: (error?: any) => void, returnResponse?: boolean) => Promise<any>;
};

type TokenRefreshProviderProps = { children: React.ReactNode };

export const TokenRefreshContext = React.createContext<ContextType>({
    crabFetch: () => {
        throw Error('Cannot use a context without a provider');
    }
});

// https://codesandbox.io/p/sandbox/confirm-prompt-y8ew9s?file=%2Fsrc%2FApp.tsx%3A31%2C14&from-embed=
// 24 hours till logout 
const timeout = 86000000;
// propt 60 seconds before
const promptBeforeIdle = 60000;
const startManually = true;

export default function TokenRefreshProvider({ children }: TokenRefreshProviderProps) {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState<boolean>(false);

    React.useEffect(() => {
        checkIsAuth();
        setInterval(() => {
            refreshToken();
        }, 3000000);
    }, []);

    const onIdle = () => {
        setOpen(false);
        navigate(`${ApplicationPaths.LogOut}`, {
            state: {
                local: true
            }
        });
    }

    const onActive = async () => {
        setOpen(false);
    }

    const onPrompt = () => {
        setOpen(true)
    }

    // https://idletimer.dev/docs/api/use-idle-timer
    const { activate, start } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        startManually,
    })

    const checkIsAuth = async () => {
        const isAuth = await authService.isAuthenticated();
        if (isAuth) {
            start();
        }
    }

    const refreshToken = async () => {
        const isAuth = await authService.isAuthenticated();
        if (isAuth) {
            await authService.refreshAccessToken();
        }
    }

    const preventClose = (event: any, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    }

    const crabFetch = async (url: string, options: any, type: ResponseType, responseFunc?: (data: any) => void, catchFunc?: (error?: any) => void, returnResponse?: boolean) => {
        let accessDenied = false;

        try {
            return fetch(url, options)
                .then(response => {
                    accessDenied = response.url.includes("AccessDenied");
                    //if (response.redirected) window.location.href = response.url;
                    if (type === ResponseType.JSON) return response.json()
                    if (type === ResponseType.Text) return response.text()
                    if (type === ResponseType.Blob) return response.blob()

                    return response;
                })
                .then(data => {
                    if (returnResponse) {
                        if (responseFunc) {
                            return responseFunc(data);
                        } else {
                            return data;
                        }
                    } else if (responseFunc) {
                        responseFunc(data);
                    }
                });
        }
        catch (error: any) {

            if (catchFunc) catchFunc(error);
            if (!accessDenied) Bugsnag.notify(error);
        };
    }

    return (
        <TokenRefreshContext.Provider value={{ crabFetch: crabFetch }}>
            <Dialog
                open={open}
                onClose={preventClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Inactivity Warning</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">You have been inactive for 30 minutes, please press continue to show you are active or you will be logged out in 60 seconds.</Typography>
                    <DialogActions>
                        <Button variant="contained" onClick={activate}>Continue</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            {children}
        </TokenRefreshContext.Provider>
    );
}