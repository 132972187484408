import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ResponseType, TokenRefreshContext } from './TokenRefreshContext';
import { AlertContext } from './AlertContext';
import LoadingButton from '@mui/lab/LoadingButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SubscriptionStatusViewModel from '../Billing/Viewmodels/SubscriptionStatusViewModel';
import { AdminContext } from './AdminContext';

type ContextType = {

};

type BillingProviderProps = { children: React.ReactNode };

export const BillingContext = React.createContext<ContextType>({});

export default function BillingProvider({ children }: BillingProviderProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { changeCompany } = React.useContext(AdminContext);
    const navigate = useNavigate();
    const [openPanel, setOpenPanel] = React.useState(false);
    const authSubscription = authService.subscribe(() => getData());
    const [reasonForShowing, setReasonForShowing] = React.useState('');
    const [stripeLoading, setStripeLoading] = React.useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = React.useState(new SubscriptionStatusViewModel());

    React.useEffect(() => {
        getData();

        return () => {
            authService.unsubscribe(authSubscription);
        }
    }, []);

    React.useEffect(() => {

        if (subscriptionStatus.companyId === 0) {
            setOpenPanel(false);
            return;
        }

        if (subscriptionStatus.status === "paused" || subscriptionStatus.status === "deleted" || subscriptionStatus.status === "canceled") {
            setReasonForShowing(subscriptionStatus.status);
            setOpenPanel(true);
        }
    }, [subscriptionStatus]);


    const getData = async () => {
        const authenticated = await authService.isAuthenticated();
        if (authenticated) {
            const token = await authService.getAccessToken();
            crabFetch(`Billing/GetSubscriptionStatus`, {
                method: 'GET',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
            }, ResponseType.JSON,
                (data: any) => {
                    if (data.length > 0)
                        show('error', data);

                    setSubscriptionStatus(data);
                }
            );
        }
    }

    const closePanel = async (event: any, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        else {
            const user = await authService.getUser();
            setOpenPanel(false);

            if (user && user.role === 'Admin') {
                changeCompany(null);
            }
            else {
                navigate(`${ApplicationPaths.LogOut}`, {
                    state: {
                        local: true
                    }
                });
            }
        }
    }


    const goToBillingPortal = async () => {
        const token = await authService.getAccessToken();
        setStripeLoading(true);

        crabFetch('Billing/GoToBillingPortal', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'text/plain; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain; charset=utf-8' }
        }, ResponseType.Text,
            (data: string) => {
                if (data.includes('http')) {
                    window.location.href = data;
                } else {
                    show('error', data);
                }
            }
        );
    }

    const goToCheckoutPortal = async () => {
        const token = await authService.getAccessToken();
        setStripeLoading(true);

        crabFetch('Billing/GoToCheckout', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'text/plain; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain; charset=utf-8' }
        }, ResponseType.Text,
            (data: string) => {
                if (data.includes('http')) {
                    window.location.href = data;
                } else {
                    show('error', data);
                }
            }
        );
    }


    return (
        <BillingContext.Provider value={{}}>
            <Dialog
                open={openPanel}
                onClose={closePanel}
                maxWidth="md"
                fullWidth
            >
                {reasonForShowing === "paused" &&
                    <>
                        <DialogTitle>Free Trial Expired</DialogTitle>
                        <DialogContent>
                            <Typography> Your free trial has expired. Please upgrade to a paid subscription to continue using Teamplanner.</Typography>
                            <DialogActions>
                                <LoadingButton endIcon={<OpenInNewIcon />} loading={stripeLoading} variant="contained" onClick={goToBillingPortal}>Upgrade Now</LoadingButton>
                                <Button color="error" onClick={() => closePanel(null, '')}>Decline</Button>
                            </DialogActions>
                        </DialogContent>
                    </>
                }
                {(reasonForShowing === "deleted" || reasonForShowing === "canceled") &&
                    <>
                        <DialogTitle>Subscription ended</DialogTitle>
                        <DialogContent>
                            <Typography> Your subscription has expired. Please resubscribe to continue using Teamplanner.</Typography>
                            <DialogActions>
                                <LoadingButton endIcon={<OpenInNewIcon />} loading={stripeLoading} variant="contained" onClick={goToCheckoutPortal}>Resubscribe</LoadingButton>
                                <Button color="error" onClick={() => closePanel(null, '')}>Decline</Button>
                            </DialogActions>
                        </DialogContent>
                    </>
                }

            </Dialog>
            {children}
        </BillingContext.Provider>
    );
}