import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';
import authService from '../api-authorization/AuthorizeService';
import { FormContainer, TextFieldElement, DatePickerElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { useForm } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/en-gb';
import EntitlementChangeViewModel from './Viewmodels/EntitlementChangeViewModel';
import  LoadingButton  from '@mui/lab/LoadingButton';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean, success: boolean) => void;
    type: string;
    userId: string[];
    hasSelectedAll?: boolean;
    teamFilter?: number;

}

export default function AddEntitlementChange(props: IProps) {
    const { open, onClose, type, userId, hasSelectedAll, teamFilter} = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(false);

    const formContext = useForm<EntitlementChangeViewModel>({
        defaultValues: new EntitlementChangeViewModel()
    });

    const { reset } = formContext;

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        setLoading(true);

        form.userIds = userId;
        form.type = type;
        form.hasSelectedAll = hasSelectedAll;
        form.teamFilter = teamFilter;

        crabFetch(`Company/AddCompanyEntitlementChange`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully ${type === 'Change' ? 'changed' : 'adjusted'} entitlement`);
                    closeDialog(true, true);
                    setLoading(false);
                }
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const closeDialog = (refresh: boolean, success: boolean) => {
        reset(new EntitlementChangeViewModel());
        onClose(refresh, success);
    }

    return (
        <Dialog
            open={open}
            onClose={() => closeDialog(false, false)}
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <DialogTitle>{type === 'Change' ? 'Update FTE Entitlement' : 'Adjust FTE Entitlement'}</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="change" shrink>{type === 'Change' ? "New FTE Entitlement in Days" : "Entitlement Adjustment in Days"}</InputLabel>
                                {type === 'Change' &&
                                    <TextFieldElement autoComplete='off' disabled={loading} name="change" required type={'number'} validation={{ min: 1 }} fullWidth size="small" />
                                }
                                {type !== 'Change' &&
                                    <TextFieldElement autoComplete='off' disabled={loading} name="change" required type={'number'} fullWidth size="small" />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="goesIntoEffectOn" shrink>{type === 'Change' ? "Date New Entitlement Starts" : "Date Adjustment Added"}</InputLabel>
                                <DatePickerElement name="goesIntoEffectOn" required inputProps={{ fullWidth: true, size: "small" }} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="reason" shrink>Reason</InputLabel>
                                <TextFieldElement autoComplete='off' disabled={loading} name="reason" required fullWidth size="small" multiline />
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                    <DialogActions>
                        <LoadingButton loading={loading} variant="contained" type="submit" fullWidth>{type === 'Change' ? 'Save Change' : 'Save Adjustment'}</LoadingButton>
                        <Button disabled={loading} variant="outlined" onClick={() => closeDialog(false, false)} color="error" fullWidth>Cancel</Button>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}