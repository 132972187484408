import * as React from 'react';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import StaffTableViewModel from './Viewmodels/StaffTableViewModel';
import StaffTableRow from './StaffTableRow';
import CreateStaff from './CreateStaff';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import AddCompanyEntitlementChange from '../Companies/AddCompanyEntitlementChange';
import AssignShiftPatternDialog from '../Shifts/AssignShiftPatternDialog';
import debounce from 'lodash/debounce';
import SearchIcon from '@mui/icons-material/Search';
import SetLeaveDate from './SetLeaveDate';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import SubscriptionStatusViewModel from '../Billing/Viewmodels/SubscriptionStatusViewModel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const headCells: HeadCell<StaffTableViewModel>[] = [
    { id: 'name', property: 'Name', align: "left", disablePadding: false, label: 'Name' },
    { id: 'memberOf', property: 'MemberOf', align: "left", disablePadding: false, label: 'Team' },
    { id: 'entitlementRemaining', property: 'EntitlementRemaining', align: "left", disablePadding: false, label: 'Remaining Holiday', disableSort: true },
    { id: 'status', property: 'Status', align: "left", disablePadding: false, label: 'Status' },
];

export default function StaffTable() {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof StaffTableViewModel>('name');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [results, setResults] = React.useState<StaffTableViewModel[]>([]);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [teams, setTeams] = React.useState<DropdownViewModel[]>([]);
    const [team, setTeam] = React.useState(0);
    const [changeDialogOpen, setChangeDialogOpen] = React.useState(false);
    const [dialogOpen, setAjustDialogOpen] = React.useState(false);
    const [openAdign, setOpenAssign] = React.useState(false);
    const [openSetLeaveDate, setOpenSetLeaveDate] = React.useState(false);
    const [showAdminControls, setShowAdminControls] = React.useState(false);
    const [statusFilter, setStatusFilter] = React.useState<string[]>(["Active", "Pending Invite"]);
    const [subscriptionStatus, setSubscriptionStatus] = React.useState(new SubscriptionStatusViewModel());

    React.useEffect(() => {
        getData();
        getTeams();
    }, [page, order, orderBy, rowsPerPage]);

    React.useEffect(() => {
        const token = authService.getAccessToken();
        let active = true;
        if (page === 0) {
            const orderByProp = headCells.find(f => f.id === orderBy)!.property;

            crabFetch(`Staff/GetStaffMembers?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}&team=${team > 0 ? team : ''}`, {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
                body: JSON.stringify(statusFilter)
            }, ResponseType.JSON,
                (data: any) => {
                    if (active) {
                        setResults(data.rows);
                        setCount(data.count);
                    }
                    setLoading(false);
                },
                () => {
                    setLoading(false);
                }
            );
        }
        setPage(0);

        //clean up to stop race conditions 
        return () => {
            // cancel the subscription
            active = false;
        };
    }, [search, statusFilter, team]);

    const toggleOpenAdd = () => setOpenAdd((prev) => !prev);

    const closeAdd = (refresh: boolean) => {
        if (refresh) getData();
        setOpenAdd(false);
    }

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        await crabFetch(`Billing/GetSubscriptionStatus`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setSubscriptionStatus(data);
            }
        );

        await crabFetch(`Staff/GetStaffMembers?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}&team=${team > 0 ? team : ''}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(statusFilter)
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }


    const getTeams = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setShowAdminControls(user.role !== "Staff Member");

        await crabFetch(`Team/GetTeamDropdown`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                data.unshift({ id: 0, label: 'All Teams' });
                setTeams(data);
            }
        );
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof StaffTableViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value);
    }

    const debouncedhandleSearch =
        React.useCallback!(
            debounce(handleSearch, 600),
            []
        );

    const closeDialog = (refresh: boolean, success: boolean) => {
        setChangeDialogOpen(false);
        setAjustDialogOpen(false);
        setOpenAssign(false);
        setOpenSetLeaveDate(false);
        if (refresh) {
            getData();
        }
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onTeamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = isNaN(e.target.valueAsNumber) ?
            e.target.value :
            e.target.valueAsNumber;

        setTeam(value as number);
    }

    const emptyRows = page > 0 ? Math.max(0, rowsPerPage - results.length) : 0;

    const handleAjustClick = () => {
        setAnchorEl(null);
        setAjustDialogOpen(true);
    }

    const handleChangeClick = () => {
        setAnchorEl(null);
        setChangeDialogOpen(true);
    }

    const handleAssignClick = () => {
        setAnchorEl(null);
        setOpenAssign(true);
    }

    const handleLeaveClick = () => {
        setAnchorEl(null);
        setOpenSetLeaveDate(true);
    }

    const handleStatusFilter = (event: SelectChangeEvent<typeof statusFilter>) => {
        const {
            target: { value },
        } = event;

        setStatusFilter(() => {
            return typeof value === 'string' ? value.split(',') : value;
        });
    };

    return (
        <>
            <Grid item sx={{ pb: 2 }}>
                <Typography variant="h1">Your Staff</Typography>
            </Grid>
            <Paper>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={'auto'}>
                        <Grid container spacing={1}>
                            {showAdminControls &&
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        select
                                        value={team}
                                        onChange={onTeamChange}
                                        size={'small'}
                                        autoComplete='off'
                                    >
                                        {teams.map((item) => (
                                            <MenuItem key={'team' + item.id} value={item.id}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            }
                            <Grid item xs={12} sm={4}>
                                <Select
                                    id="status-filter"
                                    multiple
                                    value={statusFilter}
                                    onChange={handleStatusFilter}
                                    input={<OutlinedInput color="primary" size="small" fullWidth />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={'Active'}>
                                        <Checkbox checked={statusFilter.indexOf('Active') > -1} />
                                        <ListItemText primary={'Active'} />
                                    </MenuItem>
                                    <MenuItem value={'Pending Invite'}>
                                        <Checkbox checked={statusFilter.indexOf('Pending Invite') > -1} />
                                        <ListItemText primary={'Pending Invite'} />
                                    </MenuItem>
                                    <MenuItem value={'Inactive'}>
                                        <Checkbox checked={statusFilter.indexOf('Inactive') > -1} />
                                        <ListItemText primary={'Inactive'} />
                                    </MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    color="primary"
                                    size="small"
                                    variant="outlined"
                                    onChange={debouncedhandleSearch}
                                    placeholder="Search..."
                                    InputProps={{
                                        endAdornment: <SearchIcon sx={{ color: '#B5ADC4' }} />,
                                    }}
                                    autoComplete='off'
                                />
                            </Grid>
                           
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={true}>
                        <Grid container spacing={1} justifyContent={'flex-end'}>
                            <Grid item >
                                <Typography variant="body1">Using {subscriptionStatus.usedSeats} / {subscriptionStatus.seatsInSubscription} seats</Typography>
                            </Grid>
                            <Grid item>
                                {showAdminControls &&
                                    <Button variant="contained" onClick={toggleOpenAdd}>
                                        Invite Staff
                                    </Button>
                                }
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    onClick={handleClick}
                                    endIcon={<KeyboardArrowDownIcon />}
                                >
                                    Update {count} Staff
                                </Button>
                                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                                    <MenuItem onClick={handleChangeClick} disableRipple>
                                        Change Entitlement
                                    </MenuItem>
                                    <MenuItem onClick={handleAjustClick} disableRipple>
                                        Adjust Entitlement
                                    </MenuItem>
                                    <MenuItem onClick={handleAssignClick} disableRipple>
                                        Assign Shift
                                    </MenuItem>
                                    {showAdminControls &&
                                        <MenuItem onClick={handleLeaveClick} disableRipple>
                                            Set Leave Date
                                        </MenuItem>
                                    }
                                </Menu>
                            </Grid>
                        </Grid>
                    </Grid>
                    {(!loading && results) &&
                        <Grid item xs={12}>
                            <TableContainer>
                                <ResponsiveTable
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="user table"
                                >
                                    {(!loading && results) &&
                                        <SortableTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            headCells={headCells}
                                            lastCells={<TableCell />}
                                        />
                                    }
                                    <TableBody>
                                        {(!loading && results) &&
                                            results.map((row: StaffTableViewModel) => {
                                                return (<StaffTableRow key={row.id} row={row} refresh={getData} />);
                                            })
                                        }
                                        {count > 0 && emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 53 * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={headCells.length + 1} />
                                            </TableRow>
                                        )}
                                        {loading &&
                                            <TableRow
                                                sx={{
                                                    height: 53 * rowsPerPage,
                                                }}
                                            >
                                                <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </ResponsiveTable>
                            </TableContainer>

                            {((!loading && results) && count > rowsPerPage) &&
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                            component="div"
                                            count={count}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    }
                </Grid>
                <CreateStaff open={openAdd} onClose={closeAdd} subscriptionStatus={ subscriptionStatus} />
                {results && results.length > 0 &&
                    <>
                        <AssignShiftPatternDialog open={openAdign} onClose={closeDialog} userId={results.map(user => user.id)} />
                        <AddCompanyEntitlementChange open={changeDialogOpen} onClose={closeDialog} type={'Change'} userId={results.map(user => user.id)} />
                        <AddCompanyEntitlementChange open={dialogOpen} onClose={closeDialog} type={'Adjustment'} userId={results.map(user => user.id)} />
                        <SetLeaveDate open={openSetLeaveDate} onClose={closeDialog} userId={results.map(user => user.id)} />
                    </>
                }
            </Paper >
            {count <= 0 && !loading &&
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    height="100%"
                >
                    <Grid item xs={12} alignContent="center" justifyContent="center" margin={6}>

                        <Typography align="center" justifySelf="center" variant="body2">No Results Found</Typography>
                    </Grid> </Grid>
            }
        </>
    );
}
